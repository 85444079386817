<template lang="pug">
.template-meta-editor
  validation-row(
    v-if="modes.includes('title')"
    asterisk
    not-empty
    v-model="meta.title"
    label="Название шаблона"
    placeholder="Введите название шаблона"
    :disabled="meta.type === EXPERT_TEMPLATE"
    @error="$emit('error', 'title')"
    @correct="$emit('correct', 'title')"
  )
  group-selector(
    v-if="modes.includes('groupId')"
    v-model:id="meta.groupId"
    label="Группа шаблонов"
    @reset:all="$emit('reset:all')"
    @hide="$emit('reset:dialog')"
  )
  manager-selector(
    v-if="modes.includes('responsible')"
    v-model="meta.responsible"
    label="Ответственный"
    :show-set-me="!isTmManager"
  )
  mailing-schedule-editor(v-if="enableEmailNotifications && modes.includes('templateMailingSchedule')" v-model="meta.templateMailingSchedule" label="Рассылка новых тендеров на почту")
  warning-message(v-if="enableEmailNotifications && (modes.includes('templateMailingSchedule') || modes.includes('responsible'))")
    | Обратите внимание, рассылка новых тендеров идет на почту ответственного лица, назначенного на шаблон. При его отсутствии, рассылка не отправляется.
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import GroupSelector from "@/components/selector/GroupSelector.vue";
import ManagerSelector from "@/components/selector/ManagerSelector.vue";
import MailingScheduleEditor from "@/components/other/MailingScheduleEditor.vue";
import WarningMessage from "@/components/ui/alert/WarningMessage.vue";

import type { PropType } from "vue";
import type { ExtendedTemplateI } from "@/stores/templates/SearchTemplatesInterface";
import { EXPERT_TEMPLATE } from "@/const/templates";

export default defineComponent({
  name: "MetaEditor",
  components: {
    WarningMessage,
    ManagerSelector,
    ValidationRow,
    GroupSelector,
    MailingScheduleEditor,
  },
  emits: [
    'error',
    'correct',
    'reset:all',
    'reset:dialog',
  ],
  props: {
    meta: {
      type: Object as PropType<ExtendedTemplateI>,
      default: () => ({}),
    },
    modes: {
      type: Array as PropType<string[]>,
      default: () => (['title', 'groupId', 'responsible', 'templateMailingSchedule'])
    }
  },
  setup() {

    const { isTmManager, enableEmailNotifications } = useUserAccess();

    return {
      isTmManager,
      enableEmailNotifications,
      EXPERT_TEMPLATE,
    }
  }
});
</script>

<style scoped lang="scss">
.template-meta-editor {
  display: flex;
  flex-flow: column;
  gap: 16px;
}
</style>
