import { ref } from "vue";

export function useRequests() {

  const loader = ref(false);
  const error = ref(false);
  const count = ref(0);

  function sendRequests(data: any[], request: Function) {
    loader.value = true
    count.value = 0
    error.value = false

    return new Promise((resolve, reject) => {
      Promise.allSettled(data.map(request))
        .then((result) => {
          const cnt = result.filter(e => e?.status === 'rejected').length
          if (cnt === 0) resolve(null)
          else {
            count.value = cnt
            error.value = true
            reject(result.filter(e => e?.status === 'rejected').map(e => e?.reason || null))
          }
          loader.value = false
        })
    })
  }


  return {
    loader,
    error,
    count,
    sendRequests,
  }
}
