import omit from "lodash/omit";

import { computed, ref, watch } from "vue";
import { getEntityListInfo, } from "@/utils/getters/defaultRequestInfo";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { downloadFromBackend } from "@/utils/download/fileDownloader";
import { storeToRefs } from "pinia";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { getDefaultLotsListSearchForm } from "@/utils/getters/defaultSearchForm";
import { getDefaultTemplateData } from "@/utils/templates/templateFormatter";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { useSearchTemplates } from "@/use/search/useSearchTemplates";
import {
  checkCostFilter, checkTagFiltersOnActuality,
  prepareNotEmptyPreFiltrationAndSorting,
  tagsToIdList, validateSearchForm
} from "@/utils/searchForm/searchFormWorker";

import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import useAuthStore from "@/stores/auth/useAuthStore";
import useSearchStore from "@/stores/search/useSearchStore";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";
import type { ExtendedTemplateI } from "@/stores/templates/SearchTemplatesInterface";
import { ErrorMessageMode, useSearchErrors } from "~/use/other/useSearchErrors";
import {
  cancelRequest,
  commonSupplemental,
  getEntitiesList
} from "~/utils/api/searchRequests";
import { addYears } from "date-fns";

const fetchData = ref(null);
const fetchFilters = ref({});

const fastSearchData = ref<LotItemInterface[]>([]);
const fastSearchInfo = ref<MainStreamListInfo>(getEntityListInfo());

const fetchMode = ref({
  templateMode: 'byTemplate',
  fullSearchMode: 'newSearch',
  simpleSearchMode: 'newSearch',
});

// todo чистить эти данные при логауте
export function useLotsList() {

  const authStore = useAuthStore();
  const searchStore = useSearchStore();
  const templatesStore = useTemplatesStore();
  const interfaceStore = useInterfaceSettingsStore();

  const { showWarning } = useNotificationsStore();

  const {
    needReload,
    searchPageMode,
    selectedTemplatesIds,
    selectedTemplateMeta,
    dateCreateFilter,
  } = storeToRefs(searchStore);

  const {
    setSelectedTemplateIds,
    getSearchFormCopy,
    getPreFiltrationFormCopy,
    updateSearchFormData,
    updatePreFiltrationForm,
    resetSearchFormData,
    resetPreFiltrationFormData,
    resetTemplateData,
  } = searchStore;

  const { isTmManager} = useUserAccess();
  const { managerCompanyTitle, assignCompanyId } = useSuperAccount();

  const data = ref<LotItemInterface[]>([]);
  const info = ref<MainStreamListInfo>(getEntityListInfo(searchPageMode.value === 'templateMode' ? 'byTemplate' : 'newSearch'));

  const searchForm = ref(getSearchFormCopy());
  const preFiltrationForm = ref(getPreFiltrationFormCopy());

  const isLotsDownloading = ref(false);
  const showTemplateSearchForm = ref(false);

  const searchFormData = computed(() => getSearchFormCopy());

  const searchFormOmitNames = computed<string[]>(() => {
    if (searchPageMode.value === 'simpleSearchMode') return ["lotStatusIds", "wordSearchMode", "industrySearchMode", "datePublic"]
    else return  ["lotStatusIds", "wordSearchMode", "industrySearchMode"]
  })

  const hasFiltersChanges = computed(() => !isEqual(searchForm.value, searchFormData.value));
  const hasDefaultFiltersChanges = computed(() =>
    !isEqual(
      omit(searchForm.value, searchFormOmitNames.value),
      omit(getDefaultLotsListSearchForm(), searchFormOmitNames.value),
    ));

  /** search mode slider */
  function onChangeMode(value: "templateMode" | "fullSearchMode" | "simpleSearchMode") {
    resetEntitiesList(data, info)

    fetchData.value = null
    fetchFilters.value = {}

    searchPageMode.value = value;

    searchForm.value = getSearchFormCopy()
    preFiltrationForm.value = getPreFiltrationFormCopy()

    if (searchPageMode.value !== 'simpleSearchMode') loadData()
    else if (hasDefaultFiltersChanges.value && fastSearchData.value.length) {
      fetchData.value = getFetchData('newSearch')
      fetchFilters.value = getFetchFilters();
    }
  }

  function copyResultsToExtendPage() {
    data.value = fastSearchData.value
    info.value = cloneDeep(fastSearchInfo.value)

    searchPageMode.value = 'fullSearchMode';

    updateSearchFormData(searchForm.value)
    updatePreFiltrationForm(preFiltrationForm.value)
  }

  function openTemplatePage(p?: { meta: ExtendedTemplateI, filters: SearchFormI }) {
    resetEntitiesList(data, info)

    fetchData.value = null
    fetchFilters.value = {}

    searchPageMode.value = 'templateMode';

    setTemplateData(p)
  }

  function setTemplateData(p?: { meta: ExtendedTemplateI, filters: SearchFormI }) {
    if (!p) {
      selectedTemplateMeta.value = getDefaultTemplateData();
      resetSearchForm()
    } else {
      if (p.meta) selectedTemplateMeta.value = cloneDeep(p.meta);
      if (p.filters) {
        searchForm.value = cloneDeep(p.filters);
        updateSearchFormData(p.filters)
      }
    }
  }

  function resetSearchForm(withStore = true) {
    searchForm.value = getDefaultLotsListSearchForm();
    if (withStore) resetSearchFormData()
  }

  function resetPreFiltrationForm(withStore = true) {
    preFiltrationForm.value = getDefaultPreFiltrationForm();
    if (withStore) resetPreFiltrationFormData()
  }

  function resetEntitiesList(dataRef: Ref<LotItemInterface[]>, infoRef: Ref<MainStreamListInfo>) {
    cancelRequest(infoRef)
    dataRef.value = [];
    infoRef.value = getEntityListInfo(searchPageMode.value === 'templateMode' ? 'byTemplate' : 'newSearch');
  }

  /** inner use only */
  function searchLots(fetchDataInner: any, showError = true) {
    getEntitiesList(searchPageMode.value === 'simpleSearchMode' ? fastSearchInfo : info, fetchDataInner, "search")
      .then((newData) => {
        if (searchPageMode.value === 'simpleSearchMode') fastSearchData.value = fastSearchData.value.concat(newData)
        else data.value = data.value.concat(newData)
      })
      .catch((message: string) => {
        if (showError) openErrorAlert(ErrorMessageMode.SearchDefaultError, message)
      })
  }

  /** inner use only */
  function searchLotsByTemplate(fetchDataInner: any, showError = true) {
    getEntitiesList(info, fetchDataInner, "search/templates")
      .then((newData) => data.value = data.value.concat(newData))
      .catch(() => {
        if (showError) openErrorAlert(ErrorMessageMode.TemplatesNotValid)
      })
  }

  /** inner use only */
  function searchSupplemental(mode: "newSearch" | "byTemplate", fetchDataInner: any) {
    return commonSupplemental(searchPageMode.value === 'simpleSearchMode' ? fastSearchInfo : info, fetchDataInner, mode === 'byTemplate' ? 'search/templates/supplemental' : 'search/supplemental')
  }

  /** download search lots */
  function downloadLots() {

    const payload = {
      ...fetchData.value,
      ...fetchFilters.value,
    };

    // todo check search without saving
    const downloadRoute = info.value.meta.mode === 'byTemplate' ? "search/templates/download" : "search/download";
    const fileName = `Поиск компании ${ isTmManager.value ? managerCompanyTitle.value : (authStore.company?.title || authStore.me.fio) }`

    downloadFromBackend(isLotsDownloading, downloadRoute, payload, fileName)
  }

  function getFetchData(mode: 'newSearch'|'withoutSaving'|'byTemplate') {
    switch (mode) {
      case 'newSearch':
      case 'withoutSaving':
        return assignCompanyId(tagsToIdList(searchForm.value, true));
      case 'byTemplate':
        const ids = selectedTemplatesIds.value;
        return assignCompanyId({
          templateIds: ids.filter((e: number) => e !== -2),
          withoutTemplate: ids.includes(-2),
          dateCreate: dateCreateFilter.value,
        });

      default:
        return null;
    }
  }

  function getFetchFilters() {
    const f = prepareNotEmptyPreFiltrationAndSorting(preFiltrationForm.value, getDefaultPreFiltrationForm())
    return checkCostFilter(searchForm.value, f)
  }

  const {
    request,
    fetchCurrentTemplate,
  } = useSearchTemplates('LotsListSearch');

  const {
    errorMessage,
    showErrorMessage,
    openErrorAlert,
  } = useSearchErrors();

  function setTemplateDataBeforeLoad(t: number, d: any) {
    selectedTemplatesIds.value = [t]
    dateCreateFilter.value = d
    searchPageMode.value = 'templateMode'
  }

  function selectSearchTemplate(payload: { templates: number[], additionalPayload?: any }, hard = true) {
    openErrorAlert(undefined)
    setTemplateData(undefined)                          // сбрасываем расширенные фильтры и мета шаблона
    if (hard) resetPreFiltrationForm();                              // сбрасываем панель фильтров и сортировок
    setSelectedTemplateIds(payload.templates);                              // указываем выбранные шаблоны

    const templateId = payload.templates.length === 1 && payload.templates[0] !== -2 ? payload.templates[0] : null
    // если были выбраны несколько шаблонов/группы, то сразу запрашиваем данные
    if (!templateId) hard ? fetchTemplateEntity(false, payload.additionalPayload) : applyFilters(true);
    else {
      // проверяем что шаблон существует, потом запрашиваем по нему данные
      fetchCurrentTemplate(templateId)
        .then((template) => {
          hard ? fetchTemplateEntity(false, payload.additionalPayload) : applyFilters(true);
          setTemplateData({ meta: template.meta, filters: template.content });
        })
        .catch((statusCode) => {
          if ([403, 404].includes(statusCode)) openErrorAlert(ErrorMessageMode.TemplateNotFound)
          else {
            hard ? fetchTemplateEntity(false, payload.additionalPayload) : applyFilters(true);
            openErrorAlert(ErrorMessageMode.TemplateError)
          }
        })
    }
  }

  /** поиск по шаблону без сохранения (через поиск) */
  function fetchLotsWithoutSaving() {
    console.log('поиск по шаблону без сохранения (через поиск)')
    resetEntitiesList(data, info)
    // resetPreFiltrationForm()

    info.value.meta.mode = "withoutSaving"
    fetchMode.value[searchPageMode.value] = "withoutSaving"
    showErrorMessage.value = false

    fetchData.value = getFetchData('withoutSaving')
    fetchFilters.value = getFetchFilters();

    const payload = {
      ...fetchData.value,
      ...fetchFilters.value,
    };

    searchLots(payload)
    searchSupplemental('newSearch', payload)
  }

  /** поиск по фильтрам */
  function fetchLots() {

    if (!hasDefaultFiltersChanges.value) {
      useNotificationsStore().showAlert('Пустой запрос', 'Нет установленных фильтров', 6000)
      return
    }

    const notValid = validateSearchForm(searchForm.value)
    if (notValid) {
      useNotificationsStore().showAlert('Ошибка валидации фильтров', notValid)
      return
    }

    scrollIntoView('main-search-content', 'start');

    console.log('поиск по фильтрам')
    resetEntitiesList(data, info)
    resetPreFiltrationForm()

    info.value.meta.mode = "newSearch"
    fetchMode.value[searchPageMode.value] = "newSearch"
    updateSearchFormData(searchForm.value)
    showErrorMessage.value = false

    fetchData.value = getFetchData('newSearch')

    searchLots(fetchData.value)
    searchSupplemental('newSearch', fetchData.value)
  }

  /** поиск по фильтрам FAST SEARCH */
  function fetchFastSearch() {
    scrollIntoView('main-search-content', 'start');

    console.log('поиск по фильтрам FAST SEARCH')
    resetEntitiesList(fastSearchData, fastSearchInfo)
    resetPreFiltrationForm()

    info.value.meta.mode = "newSearch"
    fetchMode.value[searchPageMode.value] = "newSearch"

    const regex = /\d{11,}/u

    if (searchForm.value?.lotStatusIds?.length === 4 && !searchForm.value?.lotsSearch?.keywords?.some(word => regex.test(word))) {
      searchForm.value.datePublic = { dateFrom: addYears(new Date(), -2), dateTo: new Date() }
    } else searchForm.value.datePublic = { dateFrom: null, dateTo: null }

    updateSearchFormData(searchForm.value)

    fetchData.value = getFetchData('newSearch')

    searchLots(fetchData.value)
    searchSupplemental('newSearch', fetchData.value)
  }

  /** поиск по выбранному шаблону */
  function fetchTemplateEntity(firstSearch: boolean, dateCreate?: any, reset = true) {
    console.log('поиск по шаблонам', firstSearch, dateCreate, reset)
    resetEntitiesList(data, info)
    if (reset) resetPreFiltrationForm()

    info.value.meta.mode = "byTemplate"
    fetchMode.value[searchPageMode.value] = "byTemplate"

    dateCreateFilter.value = dateCreate || undefined

    const params = getFetchData('byTemplate')
    fetchData.value = cloneDeep(params)

    if (firstSearch) params.firstSearch = true;

    searchLotsByTemplate(params);
    searchSupplemental('byTemplate', params)
      .then((unseen: number) => {
        if (selectedTemplatesIds.value.length === 1 && selectedTemplatesIds.value[0] !== -2) templatesStore.setTemplateUnseen(selectedTemplatesIds.value[0], unseen || 0)
      });
  }

  /** используется для бесконечного скролла - loadNextPart */
  function updateTemplateOutput() {
    const payload = {
      ...fetchData.value,
      ...fetchFilters.value,
    };

    if (info.value.meta.mode === "byTemplate") searchLotsByTemplate(payload, false)
    else searchLots(payload, false)
  }

  /** обновление результатов поиска - после внесения изменений в preFiltrationForm или при возвращении на страницу */
  function applyFilters(setFetchData = false) {
    updatePreFiltrationForm(preFiltrationForm.value)

    if (setFetchData) fetchData.value = getFetchData(fetchMode.value[searchPageMode.value])
    fetchFilters.value = getFetchFilters();

    scrollIntoView('main-search-content', 'start', 'instant');
    searchPageMode.value === 'simpleSearchMode' ? resetEntitiesList(fastSearchData, fastSearchInfo) : resetEntitiesList(data, info)
    info.value.meta.mode = fetchMode.value[searchPageMode.value]

    const payload = {
      ...fetchData.value,
      ...fetchFilters.value,
    };

    if (fetchMode.value[searchPageMode.value] === "byTemplate") {
      searchLotsByTemplate(payload, false)
      searchSupplemental('byTemplate', payload)
    } else {
      searchLots(payload, false)
      searchSupplemental('newSearch', payload)
    }
  }

  /** загрузка результатов при возвращении на страницу */
  function loadData(onReload = false) {
    if (searchPageMode.value === 'simpleSearchMode') return

    // проверяем теги на актуальность
    const value = checkTagFiltersOnActuality(preFiltrationForm.value?.tagIds, searchStore.tags)
    if (value) {
      preFiltrationForm.value.tagIds = value
      if (onReload) showWarning('Список тендеров был перезапрошен, так как фильтр по тегам содержал неактуальные значения', 4000, 'Внимание')
    }

    fetchData.value = null
    fetchFilters.value = {}

    if (searchPageMode.value === 'fullSearchMode' && hasDefaultFiltersChanges.value) applyFilters(true)
    if (searchPageMode.value === 'templateMode' && selectedTemplatesIds.value.length) {
      fetchMode.value[searchPageMode.value] = "byTemplate"
      showTemplateSearchForm.value = false
      // проверяем шаблоны на актуальность
      selectedTemplatesIds.value = selectedTemplatesIds.value.filter(e => !!templatesStore.templatesList.find(t => t.id === e) || e === -2)
      if (selectedTemplatesIds.value.length) selectSearchTemplate({ templates: selectedTemplatesIds.value }, false)
      else clearPageData()
    }
  }

  /** очистка страницы */
  function clearPageData() {
    searchPageMode.value === 'simpleSearchMode'
      ? resetEntitiesList(fastSearchData, fastSearchInfo)
      : resetEntitiesList(data, info) // сброс предыдущих результатов (отмена запросов, очистка списка лотов)

    resetSearchForm();
    resetPreFiltrationForm();                    // сброс панели фильтров

    fetchData.value = null;
    fetchFilters.value = {};
    fetchMode.value[searchPageMode.value] = ''

    if (searchPageMode.value === 'templateMode') resetTemplateData()
  }

  watch(needReload, (v) => {
    if (v) {
      loadData(true)
      needReload.value = false;
    }
  })

  return {
    data,
    info,
    fastSearchData,
    fastSearchInfo,
    fetchFastSearch,
    searchPageMode,
    fetchMode,
    request,
    isLotsDownloading,
    preFiltrationForm,
    fetchData,
    searchForm,
    hasFiltersChanges,
    hasDefaultFiltersChanges,
    selectedTemplateMeta,
    errorMessage,
    showErrorMessage,
    showTemplateSearchForm,
    loadData,
    resetSearchForm,
    selectSearchTemplate,
    setTemplateDataBeforeLoad,
    setTemplateData,
    openTemplatePage,
    setSelectedTemplateIds,
    fetchLots,
    fetchTemplateEntity,
    fetchLotsWithoutSaving,
    updateTemplateOutput,
    applyFilters,
    downloadLots,
    clearPageData,
    onChangeMode,
    copyResultsToExtendPage,
  }
}
