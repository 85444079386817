import { ref } from "vue";

const TemplateGetterError = {
  type: 'template',
  label: "Произошла ошибка при получении шаблона",
  message: "Редактирование шаблона недоступно, пожалуйста, повторите попытку позже",
}

const TemplateNotFoundError = {
  type: 'template',
  label: "Шаблон не найден",
  message: "Возможно, Вы воспользовались неисправной ссылкой или шаблон был удален. Пожалуйста, воспользуйтесь селектором шаблонов для поиска по шаблонам.",
}

const DefaultSearchError = {
  label: "Произошла ошибка при поиске по фильтрам",
  message: "Пожалуйста, проверьте корректность заполнения фильтров, ключевых слов или профессионального поиска",
}

const NotValidTemplatesError = {
  label: "Произошла ошибка при поиске по шаблонам",
  message: "Пожалуйста, проверьте корректность заполнения фильтров, ключевых слов или профессионального поиска",
}

export const ErrorMessageMode = {
  TemplateNotFound: 'templateNotFound',
  TemplateError: 'templateError',
  SearchDefaultError: 'searchDefaultError',
  TemplatesNotValid: 'templatesNotValid',
} as const

type ErrorMessageModeTypes = typeof ErrorMessageMode[keyof typeof ErrorMessageMode]

function getErrorAlert(mode: ErrorMessageModeTypes) {
  switch(mode) {
    case ErrorMessageMode.TemplateNotFound: return TemplateNotFoundError;
    case ErrorMessageMode.TemplateError: return TemplateGetterError;
    case ErrorMessageMode.SearchDefaultError: return DefaultSearchError;
    case ErrorMessageMode.TemplatesNotValid: return NotValidTemplatesError;
  }
}

export function useSearchErrors() {

  const errorMessage = ref(DefaultSearchError);           // данные для alert с ошибкой
  const showErrorMessage = ref(false);

  function openErrorAlert(mode: ErrorMessageModeTypes|undefined, message?: string) {
    if (!mode) showErrorMessage.value = false;
    else {
      errorMessage.value = getErrorAlert(mode);
      if (message) errorMessage.value.message = message
      showErrorMessage.value = true;
    }
  }

  return {
    errorMessage,
    showErrorMessage,
    openErrorAlert,
  }
}
