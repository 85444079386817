<template lang="pug">
ui-dialog(
  modal
  closable
  width="520px"
  :visible-prop="show"
  header="Подтверждение действия"
  @content:hide="hide"
)
  template(#content)
    span.info-message(v-if="mode === 'status'") Для перевода выбранных закупок в статус <b>"{{ selectedStatus?.title }}"</b> нажмите кнопку "Подтвердить"
    span.info-message(v-if="mode === 'tag'") Для назначение тега <b>"{{ selectedTag?.title }}"</b> на выбранные закупки нажмите кнопку "Подтвердить"
    warning-message(v-if="selectedStatus?.id === AppStatusWin") При назначении статуса "Победа", карточки контракта созданы не будут.
    ui-checkbox(id="clear-after-modify" label="Снять выбор лотов после внесения изменений" v-model="clearAfter")
    span.error(v-if="error") При обновлении {{ count }} {{ declineTenderWordGenitiveCase(count) }} произошла ошибка, повторите попытку
  template(#actions)
    ui-button(type="secondary" @click.stop="hide") Отменить
    ui-button(:loader="loader" @click.stop="applyChanges") {{ error ? 'Повторить попытку' : 'Подтвердить' }}
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRequests } from "~/use/other/useRequests";
import { declineTenderWordGenitiveCase } from "~/utils/formatter/wordDeclension";
import { useAppModifier } from "~/components/pages/applications/list/useAppModifier";
import { useLotTags } from "~/components/tags/useLotTags";
import { AppStatusWin } from "@/const/statuses";

import UiButton from "~/components/ui/button/UiButton.vue";
import UiDialog from "~/components/ui/dialog/UiDialog.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";

import type { PropType } from 'vue';
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "ConfirmAction",
  components: {
    UiCheckbox,
    UiButton,
    WarningMessage,
    UiDialog,
  },
  methods: {
    declineTenderWordGenitiveCase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<LotItemInterface[]>,
      default: () => ([]),
    },
    mode: {
      type: String as PropType<'status'|'tag'>,
      default: '',
    },
    selectedStatus: {},
    selectedTag: {},
  },
  emits: [
    'update:show',
    'reset',
  ],
  setup(props, context) {

    const {
      createNewApp,
      updateAppStatus,
    } = useAppModifier();

    const {
      loader,
      error,
      count,
      sendRequests,
    } = useRequests();

    const clearAfter = ref(true);

    const { applyTenderTag } = useLotTags(false);

    function request(lot: LotItemInterface) {
      return applyTenderTag(lot, props.selectedTag?.id)
    }

    function updateLot(lot: LotItemInterface) {
      if (lot.app) return updateAppStatus(lot, props.selectedStatus, null, false, false)
      else return createNewApp(lot, props.selectedStatus, false)
    }

    function applyChanges() {
      if (props.mode === 'status' && !props.selectedStatus?.id) return
      if (props.mode === 'tag' && !props.selectedTag?.id) return

      sendRequests(props.data, props.mode === 'status' ? updateLot : request)
        .then(() => {
          hide()
          if (clearAfter.value) context.emit("reset")
        })
        .catch((lots) => {
          const errorLots = lots.filter(Boolean)
          if (clearAfter.value && count.value !== props.data.length) context.emit("reset", props.data.filter(e => errorLots.includes(e.lotId)))
        })
    }

    function hide() {
      context.emit('update:show', false);
    }

    return {
      loader,
      error,
      count,
      clearAfter,
      hide,
      applyChanges,
      AppStatusWin,
    };
  },
})
</script>

<style scoped lang="scss">
.error {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-red-color);
}

.info-message {
  font-size: 14px;
  line-height: 20px;
}
</style>
