<template lang="pug">
form-input-container(:label="label")
  .mailing-schedule
    checkbox-group(v-model="mailingSchedule" :options="timesList" field="time" id="mailing-schedule")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";

import type { PropType } from "vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "MailingScheduleEditor",
  components: {
    CheckboxGroup,
    FormInputContainer
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {

    const manualsStore = useManualsStore()
    const { mailingSchedule: timesList } = storeToRefs(manualsStore)

    const mailingSchedule = useVModel(props, 'modelValue', emit);

    return {
      timesList,
      mailingSchedule,
    }
  }
})
</script>

<style scoped lang="scss">
.mailing-schedule {
  :deep(.checkbox-group) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
