<template lang="pug">
.action-panel
  span.panel-title Панель групповых действий:
  ui-checkbox(v-if="results <= limit && results !== 0" v-model="selectAllGetter" id="select-all-lots" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
  span.panel-disabled(v-if="isDisabled") Для активации групповых действий выберите как минимум 1 тендер
  .content(v-else)
    .actions(v-if="appStatuses.length")
      span Назначить статус:
      icon-button(:icon="UiIconNames.Icon_StarO" :size="16" :disabled="isDisabled" tooltip-title="Расчет заявки (избранное)" @click="selectStatus(appStatuses.find(e => e.id === AppStatusFavourite))")
      icon-button(:icon="UiIconNames.Icon_Block" :size="16" :disabled="isDisabled" tooltip-title="Отказ от закупки" @click="selectStatus(appStatuses.find(e => e.id === AppStatusBlock))")
      ui-dropdown(:disabled="isDisabled")
        icon-button(:icon="UiIconNames.Chevron_ArrowDown" :size="16" :disabled="isDisabled")
        template(#dropdown="{ close }")
          .dropdown
            .status(v-for="status of appStatuses" :key="`app-status-${status.id}`" @click="selectStatus(status, close)") {{ status.title }}

    .block(v-if="!isTmManager && tags.length")
      span Назначить тег:
      .full-view(v-if="tags.length < 7")
        lot-tag(v-for="tag of tags" hide-label :tag="tag" size="mini" :disabled="isDisabled" @click="selectTag(tag)")
      tags-selector(v-else from-action-panel @on:select="selectTag")
    span.count Выбрано лотов: {{ count }}
    icon-button(:icon="UiIconNames.Icon_CircleClose" :size="16" :disabled="isDisabled" tooltip-title="Снять выбор лотов" @click="$emit('reset')")
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { storeToRefs } from "pinia";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import LotTag from "~/components/tags/LotTag.vue";
import UiDropdown from "~/components/ui/dropdown/UiDropdown.vue";
import TagsSelector from "~/components/tags/TagsSelector.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import useSearchStore from "~/stores/search/useSearchStore";
import useManualsStore from "~/stores/manuals/useManualsStore";
import type { StatusI } from "~/stores/manuals/ManualsInterface";
import type { TenderTagI } from "~/stores/search/LotItemInterface";
import { AppStatusFavourite, AppStatusBlock, AppStatusWin } from "@/const/statuses";

export default defineComponent({
  name: "ActionPanel",
  components: {
    TagsSelector,
    UiDropdown,
    LotTag,
    IconButton,
    UiCheckbox,
  },
  emits: [
    'reset',
    'all',
    'status',
    'tag',
  ],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    results: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {

    const searchStore = useSearchStore();
    const { tags } = storeToRefs(searchStore);

    const manualsStore = useManualsStore()
    const { appStatuses } = storeToRefs(manualsStore)

    const { isTmManager } = useUserAccess();

    const selectAllGetter = computed({
      get: () => props.count > 0 && props.count === props.results,
      set(value: boolean) {
        if (value) context.emit('all')
        else context.emit('reset')
      }
    })

    const isIndeterminate = computed(() => props.count > 0)
    const isDisabled = computed(() => props.count === 0)

    function selectStatus(status: StatusI, close?: Function) {
      if (!isDisabled.value) context.emit('status', status)
      if (close) close()
    }

    function selectTag(tag: TenderTagI) {
      if (!isDisabled.value) context.emit('tag', tag)
    }

    return {
      tags,
      appStatuses,
      isIndeterminate,
      isDisabled,
      selectAllGetter,
      isTmManager,
      selectTag,
      selectStatus,
      AppStatusFavourite,
      AppStatusBlock,
      AppStatusWin,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.action-panel {
  display: flex;
  flex-flow: row;
  gap: 20px;

  font-size: 13px;
  line-height: 16px;

  align-items: center;
  position: sticky;
  top: 54px;
  z-index: 1;
  border: 1px solid #c6e2ff;
  background-color: #ecf5ff;
  min-height: 42px;
  box-sizing: border-box;
  padding: 8px 12px 8px 18px;

  .content {
    display: contents;
  }

  .panel-disabled {
    color: var(--disabled-text-color);
  }

  .count {
    margin-left: auto;
  }

  .block, .full-view {
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: center;
  }

  .actions {
    display: flex;
    flex-flow: row;
    gap: 0;

    span {
      margin-right: 8px;
    }
  }

  .status {
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f5f7fa;
    }
  }

  .dropdown {
    display: flex;
    flex-flow: column;
    padding: 6px 0;
  }
}
</style>
