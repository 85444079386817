<template lang="pug">
.search-form
  .simple-search
    .simple-search-content
      .block
        .keywords-block
          keywords-form(v-model="searchForm" simple @reset="resetField")
        ui-checkbox.margin.m8(
          id="simple-overlap_keywords"
          :model-value="useDocs"
          :disabled="!actualLotsOnly"
          label="Поиск по документации"
          hint="При установке данного параметра, результаты поиска будут содержать тендеры, в которых введенные слова встретились в названии тендера, объектах закупки или в файлах документации. Поиск по документации осуществляется только среди актуальных тендеров"
          hint-type="info"
          hint-size="small"
          @update:model-value="useLotsSearchKeywords"
        )
      by-industry-form.industry(v-model="searchForm" v-model:operatorMode="operatorMode" @reset="resetField")
    .simple-search-content
      regions-tree(v-model="searchForm" @reset="resetField")
      .flex-content
        laws-simple(v-model="searchForm" @reset="resetField")
        ui-radio-button.margin.m24(v-model="actualLotsOnly" :options="lotStatusOptions")
  search-form-buttons(
    tab="LotsListSearch"
    :show-copy-button="hasDefaultChanges"
    @form:reset="resetForm"
    @start:search="startSearch"
    @save:template="$emit('save:template')"
    @form:extend="$emit('form:extend')"
  )
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVModel } from "@vueuse/core";
import { useSearchFormCommon } from "@/use/searchForm/useSearchForm";
import { setDocsWords } from "~/utils/searchForm/searchFormWorker";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";

import SearchFormButtons from "@/components/searchForm/SearchFormButtons.vue";
import KeywordsForm from "@/components/searchForm/filters/KeywordsForm.vue";
import ByIndustryForm from "@/components/searchForm/filters/IndustryTree.vue";
import RegionsTree from "@/components/searchForm/filters/RegionsTree.vue";
import LawsSimple from "~/components/searchForm/filters/LawsSimple.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import UiRadioButton from "~/components/ui/radio/UiRadioButton.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

import type { PropType } from 'vue';
import type { SearchFormI } from "@/stores/search/SearchFormInterface";

import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SimpleSearchForm",
  components: {
    WarningMessage,
    UiRadioButton,
    KeywordsForm,
    ByIndustryForm,
    RegionsTree,
    UiCheckbox,
    LawsSimple,
    SearchFormButtons,
  },
  methods: {
    formatDateDMY,
  },
  emits: [
    'update:searchForm',
    'start:search',        // начать поиск
    'save:template',       // сохранить шаблон
    'form:extend',         // перенести фильтры и результаты поиска в расширенную панель
  ],
  props: {
    searchForm: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
    hasDefaultChanges: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const searchFormModel = useVModel(props, 'searchForm', context.emit);

    const useDocs = ref(!!searchFormModel.value.docsSearch.keywords.length || !!searchFormModel.value.docsSearch.exceptionWords.length);

    const operatorMode = ref(2);
    const { resetCurrentField, resetSearchForm } = useSearchFormCommon()

    const lotStatusOptions = [
      { id: 'actual-lots-slider', label: 'Поиск по актуальным закупкам <br/> (с этапом "Подача заявки")', value: true },
      { id: 'non-actual-lots-slider', label: 'Поиск по всем закупкам <br/> (в том числе завершенным)', value: false },
    ]

    function useLotsSearchKeywords(v: boolean) {
      useDocs.value = v
      searchFormModel.value.wordSearchMode = 2
      if (v) setDocsWords(searchFormModel)
      else {
        searchFormModel.value.docsSearch.keywords = []
        searchFormModel.value.docsSearch.exceptionWords = []
      }
    }

    const actualLotsOnly = computed({
      get(): boolean {
        return searchFormModel.value?.lotStatusIds?.length === 1 && searchFormModel.value?.lotStatusIds[0] === 1
      },
      set(v: boolean): void {
        if (v) searchFormModel.value.lotStatusIds = [1]
        else {
          useLotsSearchKeywords(false)
          searchFormModel.value.lotStatusIds = [1, 2, 3, 4]
        }
      }
    })

    watch(() => [props.searchForm.lotsSearch.keywords, props.searchForm.lotsSearch.exceptionWords], () => {
      if (useDocs.value) setDocsWords(searchFormModel);
    }, { deep: true });

    /** очистить фильтр */
    function resetField(field: string) {
      resetCurrentField(searchFormModel, 'LotsListSearch', field)
    }

    /** сбросить все фильтры */
    function resetForm() {
      resetSearchForm(searchFormModel, 'LotsListSearch')
      useDocs.value = false
      operatorMode.value = 2;
    }

    /** начать новый поиск */
    function startSearch() {
      context.emit("start:search");
    }

    return {
      lotStatusOptions,
      useDocs,
      searchFormModel,
      operatorMode,
      actualLotsOnly,
      resetField,
      resetForm,
      startSearch,
      useLotsSearchKeywords,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.search-form {
  display: flex;
  flex-flow: column;

  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 8px 10px #cdcdcd24, 0 3px 15px #b9bfc51f, 0 4px 5px #cfcfcf33;
  background-color: white;
}

.simple-search {
  display: flex;
  flex-flow: column;
  gap: 32px;

  padding: 40px 44px 16px 44px;

  .simple-search-content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 32px;
  }

  .industry {
    height: 100%;
    display: flex;
    flex-flow: column;

    :deep(.inner-industry-tree) {
      display: flex;
      flex-flow: column;
      height: 100%;
    }
  }

  .keywords-block {
    display: flex;
    flex-flow: column;
    gap: 16px;

    :deep(.keywords-filter) {
      display: contents;
    }

    :deep(.tags-exceptions) {
      min-height: unset !important;
    }
  }

  .flex-content {
    display: flex;
    flex-flow: column;

    &.g16 {
      gap: 24px;
    }
  }

  .margin.m8 {
    margin-top: 8px;
  }

  .margin.m20 {
    margin-top: 20px;
  }

  .margin.m24 {
    margin-top: 24px;
  }
}
</style>
