import { ref } from "vue";
import { storeToRefs } from "pinia";
import isEqual from "lodash/isEqual";
import useSearchStore from "@/stores/search/useSearchStore";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export function useTemplateChanges() {

  const searchStore = useSearchStore();

  const {
    searchFormDataTemplates,
    selectedTemplateId,
    selectedTemplatesIds,
    selectedTemplateMeta,
  } = storeToRefs(searchStore);

  let next : Function;
  const showPopConfirm = ref(false);

  /**
   * открытие модалки "у вас есть несохраненные изменения"
   */
  function openPopConfirm(value: boolean, _next: Function) {
    showPopConfirm.value = value;
    next = _next;
  }

  /**
   * actions у модалки "у вас есть несохраненные изменения"
   * пока она не ничего не сохраняет, в любом случае продолжает без сохранения
   * */
  function popConfirmEvent(event: string) {
    switch (event) {
      case 'dontSaveAndExit':
      case 'saveAndExit': {
        if (next) next();
        break;
      }
    }
    showPopConfirm.value = false;
  }

  /** проверка есть ли изменения сохраненных фильтров шаблона
   * если есть - показать модалку "сохранить изменения"
   * если нет - продолжить
   * */
  function checkTemplateChanges(filters: BaseSearchFormInterface, _next: Function) {
    !isEqual(searchFormDataTemplates.value, filters) ? openPopConfirm(true, _next) : _next()
  }

  return {
    selectedTemplateId,
    selectedTemplateMeta,
    showPopConfirm,
    selectedTemplatesIds,
    popConfirmEvent,
    checkTemplateChanges,
  };
}
