<template lang="pug">
.fast-search
  simple-search-form(
    v-model:searchForm="searchFormModel"
    :has-default-changes="hasDefaultChanges"
    @form:extend="$emit('form:extend')"
    @start:search="fetchLots"
    @save:template="saveNewTemplate"
  )

  alert-message(
    v-if="noResults && isOnlyActual"
    label="Актуальных закупок по Вашим данным не найдено"
  )
    template(#content)
      .content
        span Установите поиск по всем тендерам, чтобы увидеть результаты поиска по завершенным закупкам.
        ui-button.right(type="secondary" @click="searchInAlls") Искать по всем тендерам, в том числе завершенным

  alert-message(
    v-if="showResults && (searchFormModel?.datePublic?.dateFrom || searchFormModel?.datePublic?.dateTo)"
    label="Ограничение по дате публикации лота"
    type="warning"
  )
    template(#content)
      .content
        span
          | В быстром поиске при выборе параметра "искать по всем закупкам" (при условии, что поиск не происходит по номеру лота), автоматически устанавливается ограничение на дату публикации лота,
          |
          | с {{ formatDateDMY(searchFormDataFS.datePublic.dateFrom) }} до {{ formatDateDMY(searchFormDataFS.datePublic.dateTo) }}.
          | Пожалуйста, воспользуйтесь расширенным поиском или аналитикой для поиска по завершенным закупкам.
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { validateSearchForm } from "~/utils/searchForm/searchFormWorker";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { formatDateDMY } from "~/utils/formatter/dateFormatter";

import UiButton from "~/components/ui/button/UiButton.vue";
import SimpleSearchForm from "~/components/searchForm/SimpleSearchForm.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";

import type { PropType } from 'vue';
import type { SearchFormI } from "~/stores/search/SearchFormInterface";

import useSearchStore from "~/stores/search/useSearchStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export default defineComponent({
  name: "FastSearch",
  components: {
    UiButton,
    SimpleSearchForm,
    AlertMessage,
  },
  methods: {
    formatDateDMY,
  },
  emits: [
    'update:searchForm',
    'template:create',
    'fetch:lots',
    'form:extend',
  ],
  props: {
    searchForm: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
    hasDefaultChanges: {
      type: Boolean,
      default: false,
    },
    noResults: {
      type: Boolean,
      default: false,
    },
    showResults: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const showErrorMessage = ref(false);
    const searchFormModel = useVModel(props, 'searchForm', context.emit)

    const { showAlert } = useNotificationsStore();

    const searchStore = useSearchStore()
    const { searchFormDataFS } = storeToRefs(searchStore)

    const isOnlyActual = computed(() => searchFormDataFS.value?.lotStatusIds?.length === 1)

    function validateFilters() {

      if (!props.hasDefaultChanges) {
        showAlert('Пустой запрос', 'Нет установленных фильтров', 6000)
        return false;
      }

      const notValid = validateSearchForm(searchFormModel.value)
      if (notValid) {
        showAlert('Ошибка валидации фильтров', notValid, 6000)
        return false
      }

      return true;
    }

    /** открытие диалога - сохранить новый шаблон */
    function saveNewTemplate() {
      if (validateFilters()) context.emit('template:create', searchFormModel.value)
    }

    /** поиск по фильтрам */
    function fetchLots() {
      if (validateFilters()) context.emit('fetch:lots', searchFormModel.value)
    }

    /** поиск по всем тендерам, вместо актуальных */
    function searchInAlls() {
      searchFormModel.value.lotStatusIds = [1, 2, 3, 4]
      fetchLots()
    }

    return {
      isOnlyActual,
      showErrorMessage,
      searchFormModel,
      searchFormDataFS,
      fetchLots,
      searchInAlls,
      saveNewTemplate,
    }
  }
})
</script>

<style scoped lang="scss">
.fast-search {
  display: flex;
  flex-flow: column;
  gap: 24px;

  margin-bottom: 40px;
}

.content {
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: center;

  .right {
    margin-left: auto;
  }
}
</style>
