<template lang="pug">
ui-select(
  clearable
  placeholder="Выберите группу шаблона"
  no-data-text="У вас нет созданных групп"
  :teleported="true"
  :label="label"
  :options="groups"
  :model-value="id"
  @update:modelValue="setGroup($event ? $event : null)"
)
  template(#option="{ item }")
    .group-selector__option
      span {{ item.title }}
      .actions
        icon-button(
          :size="16"
          :icon="UiIconNames.Icon_Edit"
          @click.stop="onRenameGroupClick(item, setGroup)"
        )
        icon-button(
          :size="16"
          :icon="UiIconNames.Icon_Delete"
          @click.stop="onDeleteGroupClick(item, deleteSelected)"
        )

  template(#footer)
    ui-button(
      style="margin: 0 8px;"
      type="secondary"
      size="small"
      @click.stop="onCreateGroupClick(setGroup)"
    ) + Создать новую группу шаблонов

</template>

<script lang="ts">
import { defineComponent, computed, } from "vue";
import { useSearchGlobals } from "~/use/search/useSearchGlobals";

import type { GroupI } from "@/stores/templates/SearchTemplatesInterface";
import UiButton from "@/components/ui/button/UiButton.vue";
import UiSelect from "@/components/ui/select/select/UiSelect.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "GroupSelector",
  components: {
    UiButton,
    UiSelect,
    IconButton,
  },
  emits: [
    "update:id",
    "reset:all",
    "hide",
  ],
  props: {
    id: {
      type: Number,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const groups = computed<GroupI[]>(() => useTemplatesStore().groupsList.filter(g => g.id !== 0));

    const {
      group,
      onRenameGroupClick,
      onCreateGroupClick,
      onDeleteGroupClick,
    } = useSearchGlobals();

    function setGroup(id: number | null) {
      context.emit('update:id', id)
    }

    function deleteSelected(hide?: boolean) {
      if (hide) context.emit('hide')
      else if (props.id && props.id === group.value?.id) setGroup(null)
    }

    return {
      groups,
      setGroup,
      deleteSelected,
      onRenameGroupClick,
      onCreateGroupClick,
      onDeleteGroupClick,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
.group-selector__option {
  display: flex;
  justify-content: space-between;

  .actions {
    display: flex;
    flex-flow: row;
    gap: 4px;
  }
}
</style>
